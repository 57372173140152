import { useCallback, useEffect } from 'react'

import { useRouter } from '../../vendor/next'

import { encodeAuthCallbackState } from './auth-callback-state'

type SignInOptions = {
  /**
   * The URL to return to after the user has signed in.
   */
  returnTo?: string | URL
  /**
   * User properties to be set on the user if a new account is created.
   */
  userProperties?: {
    landlord?: boolean | null
    professional?: boolean | null
    companyName?: string | null
    orgNumber?: string | null
  }
}

export const useAuth = () => {
  const router = useRouter()

  useEffect(() => {
    router.prefetch('/login')
  })

  const pushToSignIn = useCallback(
    (options?: SignInOptions) => {
      const returnTo = new URL(options?.returnTo ?? window.location.href)

      const searchParams = new URLSearchParams({
        state: encodeAuthCallbackState({
          returnTo: returnTo.toString(),
          userProperties: options?.userProperties,
        }),
      })

      router.push(`/login?${searchParams}`)
    },
    [router],
  )

  return { pushToSignIn }
}
