import type { SVGProps } from 'react'

export function NorweiganFlag({ ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" {...restProps}>
      <g clipPath="url(#clip0_77_10038)">
        <path
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
          fill="#F0F0F0"
        />
        <path
          d="M0.826782 30.2609C2.21541 35.4133 5.27588 39.879 9.39131 43.0411V30.2609H0.826782Z"
          fill="#D80027"
        />
        <path
          d="M21.9131 47.9087C22.601 47.9679 23.2967 48 24.0001 48C35.0887 48 44.4193 40.4793 47.1733 30.2609H21.9131V47.9087Z"
          fill="#D80027"
        />
        <path
          d="M47.1733 17.7391C44.4193 7.52072 35.0887 0 24.0001 0C23.2967 0 22.601 0.0320625 21.9131 0.0913125V17.7391H47.1733Z"
          fill="#D80027"
        />
        <path
          d="M9.39131 4.95889C5.27588 8.12108 2.21541 12.5867 0.826782 17.7391H9.39131V4.95889Z"
          fill="#D80027"
        />
        <path
          d="M47.7968 20.8696H18.7827H18.7826V0.570465C16.5647 1.06228 14.462 1.85943 12.5217 2.91815V20.8694V20.8695H0.203156C0.0695625 21.8943 0 22.939 0 24C0 25.061 0.0695625 26.1057 0.203156 27.1304H12.5216H12.5217V45.0817C14.462 46.1404 16.5647 46.9377 18.7826 47.4294V27.1307V27.1305H47.7968C47.9303 26.1057 48 25.061 48 24C48 22.939 47.9303 21.8943 47.7968 20.8696Z"
          fill="#0052B4"
        />
        <g clipPath="url(#clip1_77_10038)">
          <path d="M-5 0.00012207H67.0001V48.0007H-5V0.00012207Z" fill="#D80027" />
          <path
            d="M67.0001 29.6086H23.4337V48H12.2174V29.6086H-5V18.3906H12.2174V-0.000610352H23.4337V18.3906H67.0001V26.9994V29.6086Z"
            fill="white"
          />
          <path
            d="M67.0001 20.9999V26.9994H20.8261V48H14.8251V26.9994H-5V20.9999H14.8251V-0.000610352H20.8261V20.9999H67.0001Z"
            fill="#2E52B2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_10038">
          <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_10038">
          <rect width="72.0001" height="48" fill="white" transform="translate(-5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
