import { createLucideIcon } from '@qasa/qds-ui'
import {
  Palmtree,
  FileCheck2,
  LayoutList,
  UserSquare2,
  PlusCircle,
  Headset,
  FileUp,
  CircleUserRound,
  Scale,
  ClipboardList,
  ArmchairIcon,
  KeyRound,
  CircleSlash,
  Clock,
  Frown,
  Rocket,
  Dices,
  PawPrint,
  File,
  House,
  ClipboardPen,
  Sofa,
  EyeOff,
  Eye,
  CalendarCheck,
  Shield,
  ShieldCheck,
  Banknote,
  Contact2,
  Lock,
  Landmark,
  IdCard,
  CalendarDays,
  FilePen,
  WashingMachine,
  MessageCircleWarning,
  BadgeCheck,
  Coins,
  Users2,
  RotateCcw,
} from 'lucide-react'

export const UserSquareIcon = createLucideIcon(UserSquare2)
export const PlusCircleIcon = createLucideIcon(PlusCircle)
export const LayoutListIcon = createLucideIcon(LayoutList)
export const PalmTreeIcon = createLucideIcon(Palmtree)
export const HeadsetIcon = createLucideIcon(Headset)
export const FileUpIcon = createLucideIcon(FileUp)
export const CircleUserRoundIcon = createLucideIcon(CircleUserRound)
export const ScaleIcon = createLucideIcon(Scale)
export const ClipboardListIcon = createLucideIcon(ClipboardList)
export const ArmchairIconIcon = createLucideIcon(ArmchairIcon)
export const KeyRoundIcon = createLucideIcon(KeyRound)
export const CircleSlashIcon = createLucideIcon(CircleSlash)
export const ClockIcon = createLucideIcon(Clock)
export const FrownIcon = createLucideIcon(Frown)
export const UsersIcon = createLucideIcon(Users2)
export const RocketIcon = createLucideIcon(Rocket)
export const DicesIcon = createLucideIcon(Dices)
export const PawPrintIcon = createLucideIcon(PawPrint)
export const FileIcon = createLucideIcon(File)
export const FileCheckIcon = createLucideIcon(FileCheck2)
export const HouseIcon = createLucideIcon(House)
export const ClipboardPenIcon = createLucideIcon(ClipboardPen)
export const SofaIcon = createLucideIcon(Sofa)
export const EyeOffIcon = createLucideIcon(EyeOff)
export const EyeIcon = createLucideIcon(Eye)
export const CalendarCheckIcon = createLucideIcon(CalendarCheck)
export const ShieldIcon = createLucideIcon(Shield)
export const ShieldCheckIcon = createLucideIcon(ShieldCheck)
export const BanknoteIcon = createLucideIcon(Banknote)
export const ContactIcon = createLucideIcon(Contact2)
export const LockIcon = createLucideIcon(Lock)
export const LandmarkIcon = createLucideIcon(Landmark)
export const IdCardIcon = createLucideIcon(IdCard)
export const CalendarDaysIcon = createLucideIcon(CalendarDays)
export const FilePenIcon = createLucideIcon(FilePen)
export const WashingMachineIcon = createLucideIcon(WashingMachine)
export const MessageCircleWarningIcon = createLucideIcon(MessageCircleWarning)
export const BadgeCheckIcon = createLucideIcon(BadgeCheck)
export const CoinsIcon = createLucideIcon(Coins)
export const RotateCcwIcon = createLucideIcon(RotateCcw)
