import { graphql } from '@qasa/graphql'

export const SCHIBSTED_LOGIN = graphql(`
  mutation SchibstedLogin($input: SchibstedLoginInput!) {
    schibstedLogin(input: $input) {
      payload {
        accessToken
        user {
          private {
            requireTermsAcceptance
          }
        }
      }
      errors {
        field
        codes
      }
    }
  }
`)
