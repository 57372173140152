import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { MenuIcon, XIcon, pxToRem } from '@qasa/qds-ui'
import type { ComponentPropsWithoutRef } from 'react'
import { forwardRef } from 'react'
import { AnimatePresence, motion } from 'motion/react'

const MenuButton = styled.button(({ theme }) => ({
  color: 'inherit',
  height: pxToRem(40),
  width: pxToRem(40),
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: -8,
  borderRadius: theme.radii.full,
  // Make the tap area a square
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
  },
}))

type HamburgerMenuButtonProps = {
  isActive: boolean
} & ComponentPropsWithoutRef<'button'>

export const HamburgerMenuButton = forwardRef<HTMLButtonElement, HamburgerMenuButtonProps>(
  ({ isActive, ...restProps }, forwardedRef) => {
    const { t } = useTranslation('top_nav_bar')

    return (
      <MenuButton
        ref={forwardedRef}
        aria-label={t('icon_aria_labels.hamburger_menu')}
        type="button"
        {...restProps}
      >
        <AnimatePresence mode={'popLayout'} initial={false}>
          <motion.div
            key={isActive ? 1 : 0}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            exit={{ opacity: 0, transition: { duration: 0 } }}
          >
            {isActive ? (
              <XIcon key="cross" color="currentColor" size={24} />
            ) : (
              <MenuIcon key="menu" color="currentColor" size={24} />
            )}
          </motion.div>
        </AnimatePresence>
      </MenuButton>
    )
  },
)
