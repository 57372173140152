import { generatePath } from 'react-router-dom'
import type { ExtractRouteParams } from 'react-router'
import { stringify } from 'query-string'

import { pick } from '../utils/object'

import { routeDeclarations } from './route-declarations'

type Routes = typeof routeDeclarations
type RouteKeys = Routes[number]['key']

type IndexKeys = Exclude<keyof Routes, keyof []>
type Key<T> = T extends { key: infer K } ? K : never
type Path<T> = T extends { path: infer P } ? P : never

type FromProperties = {
  [K in IndexKeys as Key<Routes[K]>]: Path<Routes[K]>
}

type PathOf<T extends RouteKeys> = FromProperties[T]
type RouteParams<T extends RouteKeys> = ExtractRouteParams<PathOf<T>>
type QueryParams = Record<string, string | number | boolean | undefined>

const isRouteParam = ({ path, paramKey }: { path: string; paramKey: string }) => {
  return path.includes(`:${paramKey}`)
}

export function getPath<T extends RouteKeys>(id: T, params?: RouteParams<T> & QueryParams) {
  const route = routeDeclarations.find((route) => route.key === id)
  if (!route) {
    throw new Error(`No route found with key: ${id}`)
  }
  const paramKeys = Object.keys(params || {})
  const queryParamKeys = paramKeys.filter((paramKey) => !isRouteParam({ path: route.path, paramKey }))
  const queryParams = stringify(pick(params || {}, queryParamKeys))

  return generatePath(route.path, params) + (queryParams ? `?${queryParams}` : '')
}
