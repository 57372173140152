type RouteDeclarationShape = {
  key: string
  path: string
}

export const routeDeclarations = [
  {
    key: 'contentful',
    path: '/c/:slug?',
  },
  {
    key: 'firsthandContract',
    path: '/firsthand-contract',
  },
  {
    key: 'firsthandContract',
    path: '/firsthand-contract',
  },
  {
    key: 'embeddedFindHome',
    path: '/embedded-find-home',
  },
  {
    key: 'homeqRedirect',
    path: '/homeq-redirect',
  },
  {
    key: 'findTenant',
    path: '/find-tenant',
  },
  {
    key: 'findVacation',
    path: '/find-vacation',
  },
  {
    key: 'otpLogin',
    path: '/otp-login',
  },
  {
    key: 'postSignup',
    path: '/post-signup',
  },
  {
    key: 'resetPasswordRequest',
    path: '/reset-password-request',
  },
  {
    key: 'resetPassword',
    path: '/reset-password/:token?',
  },
  {
    key: 'claimContractInvitationIntro',
    path: '/claim-contract-invitation-intro/:claimKey',
  },
  {
    key: 'claimContractInvitation',
    path: '/claim-contract-invitation/:claimKey',
  },
  {
    key: 'adminLogin',
    path: '/admin-login',
  },
  {
    key: 'adminLoginAsUser',
    path: '/admin-login-as-user',
  },
  {
    key: 'leasesSignSuccess',
    path: '/leases/:contractId?/sign_success',
  },
  {
    key: 'leasesContractSendSuccess',
    path: '/leases/:contractId?/contract-send-success',
  },
  {
    key: 'profile',
    path: '/profile/:id?',
  },
  {
    key: 'authCallback',
    path: '/auth_callback',
  },
  {
    key: 'instantSign',
    path: '/home/:homeId/instant-sign',
  },
  {
    key: 'home',
    path: '/home/:homeId',
  },
  // NOTE: this is a home duplicate that is protected - it is used to force incognito mode users to login when
  // coming back from the verification that's done before publishing a home
  {
    key: 'homeFromVerify',
    path: '/home/:homeId/from-verify',
  },
  {
    key: 'createListing',
    path: '/create-listing',
  },
  {
    key: 'createListingLanding',
    path: '/create-listing-landing',
  },
  {
    key: 'logout',
    path: '/logout',
  },
  {
    key: 'instantMatch',
    path: '/instant-match/:homeId',
  },
  {
    key: 'invite',
    path: '/invite',
  },
  {
    key: 'editListing',
    path: '/edit-listing/:homeId',
  },
  {
    key: 'shortcutOptIn',
    path: '/shortcut-opt-in/:homeId',
  },
  {
    key: 'landlordCalendar',
    path: '/calendar/:homeId',
  },
  {
    key: 'applications',
    path: '/applications',
  },
  {
    key: 'invoices',
    path: '/leases/:contractId/invoices',
  },
  {
    key: 'extendLease',
    path: '/leases/:contractId/extend',
  },
  {
    key: 'leases',
    path: '/leases',
  },
  {
    key: 'tenantSigningProcess',
    path: '/tenant-signing-process/:contractId',
  },
  {
    key: 'landlordSigningProcess',
    path: '/landlord-signing-process/:contractId',
  },
  {
    key: 'homeqApplications',
    path: '/messages/homeq-applications',
  },
  {
    key: 'messagesRedirect',
    path: '/messages/:section(active|requests|closed|declined)/:conversationId?',
  },
  {
    key: 'messages',
    path: '/messages/:conversationId?',
  },
  {
    key: 'listingDashboard',
    path: '/my-listings/:homeId',
  },
  {
    key: 'myListings',
    path: '/my-listings',
  },
  {
    key: 'addBankAccount',
    path: `/settings/bank_accounts/add`,
  },
  {
    key: 'settings',
    path: `/settings`,
  },
  {
    key: 'idVerification',
    path: `/id-verification`,
  },

  {
    key: 'bankAccountVerification',
    path: '/bank-account-verification/:token',
  },
  {
    key: 'incomeVerification',
    path: `/income-verification`,
  },
  {
    key: 'creditVerification',
    path: `/credit-verification`,
  },
  {
    key: 'homePublishedSuccess',
    path: '/home-published/success/:homeId',
  },
  {
    key: 'profilePublished',
    path: '/profile-published',
  },
  {
    key: 'createTenantListing',
    path: '/create-tenant-listing',
  },
  {
    key: 'reserveHome',
    path: '/home/:homeId/reserve',
  },
  {
    key: 'reserveHomeSuccess',
    path: '/home/:homeId/reservation-success',
  },
  {
    key: 'favorites',
    path: '/favorites',
  },
  {
    key: 'confirmEmail',
    path: '/confirm-email/:emailToken',
  },
] as const satisfies readonly RouteDeclarationShape[]
export type RouteDeclaration = (typeof routeDeclarations)[number]
