import type { SVGProps } from 'react'

export function SwedishFlag({ ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" {...restProps}>
      <g clipPath="url(#clip0_77_10039)">
        <path
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
          fill="#FFDA44"
        />
        <path
          d="M13.7827 20.8696H42.7968C41.2628 9.09394 31.1937 0 19 0C17.208 0 15.4625 0.198094 13.7826 0.570469V20.8696H13.7827Z"
          fill="#0052B4"
        />
        <path
          d="M7.52169 20.8695V2.91815C0.931624 6.51384 -3.78597 13.1092 -4.79688 20.8696H7.52169V20.8695Z"
          fill="#0052B4"
        />
        <path
          d="M7.52159 27.1304H-4.79688C-3.78597 34.8907 0.931624 41.4862 7.52169 45.0817L7.52159 27.1304Z"
          fill="#0052B4"
        />
        <path
          d="M13.7826 27.1305V47.4295C15.4625 47.8019 17.208 48 19 48C31.1937 48 41.2628 38.9061 42.7968 27.1304H13.7826V27.1305Z"
          fill="#0052B4"
        />
        <g clipPath="url(#clip1_77_10039)">
          <path d="M-5 0H67.0001V48.0006H-5V0Z" fill="#0052B4" />
          <path
            d="M22 -0.000213623H13V19.4997H-5V28.4997H13V47.9996H22V28.4997H67.0001V19.4997H22V-0.000213623Z"
            fill="#FFDA44"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_10039">
          <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_10039">
          <rect width="72.0001" height="48" fill="white" transform="translate(-5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
