import type { SVGProps } from 'react'

export function EnglishFlag({ ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" {...restProps}>
      <g clipPath="url(#clip0_77_10037)">
        <path
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
          fill="#F0F0F0"
        />
        <path
          d="M4.96125 9.38831C3.07603 11.8411 1.6545 14.6681 0.826782 17.7393H13.3122L4.96125 9.38831Z"
          fill="#0052B4"
        />
        <path
          d="M47.1732 17.7393C46.3455 14.6682 44.9239 11.8412 43.0388 9.3884L34.688 17.7393H47.1732Z"
          fill="#0052B4"
        />
        <path
          d="M0.826782 30.261C1.65459 33.332 3.07613 36.1591 4.96125 38.6118L13.3119 30.261H0.826782Z"
          fill="#0052B4"
        />
        <path
          d="M38.6117 4.96134C36.159 3.07613 33.332 1.65459 30.2609 0.826782V13.3121L38.6117 4.96134Z"
          fill="#0052B4"
        />
        <path
          d="M9.38831 43.0386C11.8411 44.9239 14.6681 46.3454 17.7392 47.1732V34.688L9.38831 43.0386Z"
          fill="#0052B4"
        />
        <path
          d="M17.7391 0.826782C14.668 1.65459 11.841 3.07613 9.38831 4.96125L17.7391 13.312V0.826782Z"
          fill="#0052B4"
        />
        <path
          d="M30.2609 47.1732C33.3319 46.3454 36.159 44.9239 38.6116 43.0387L30.2609 34.688V47.1732Z"
          fill="#0052B4"
        />
        <path
          d="M34.688 30.261L43.0388 38.6119C44.9239 36.1592 46.3455 33.332 47.1732 30.261H34.688Z"
          fill="#0052B4"
        />
        <path
          d="M47.7968 20.8696H27.1306H27.1305V0.203156C26.1057 0.06975 25.061 0 24 0C22.9388 0 21.8943 0.06975 20.8696 0.203156V20.8694V20.8695H0.203156C0.06975 21.8943 0 22.939 0 24C0 25.0612 0.06975 26.1057 0.203156 27.1304H20.8694H20.8695V47.7968C21.8943 47.9303 22.9388 48 24 48C25.061 48 26.1057 47.9303 27.1304 47.7968V27.1306V27.1305H47.7968C47.9303 26.1057 48 25.0612 48 24C48 22.939 47.9303 21.8943 47.7968 20.8696Z"
          fill="#D80027"
        />
        <path
          d="M30.2609 30.261L40.9705 40.9706C41.4631 40.4782 41.9329 39.9635 42.3812 39.4298L33.2123 30.2609H30.2609V30.261Z"
          fill="#D80027"
        />
        <path
          d="M17.7391 30.261H17.7389L7.02942 40.9705C7.52179 41.4631 8.03657 41.933 8.5702 42.3813L17.7391 33.2121V30.261Z"
          fill="#D80027"
        />
        <path
          d="M17.7391 17.7393V17.7391L7.02952 7.02939C6.53696 7.52176 6.06709 8.03654 5.61877 8.57017L14.7878 17.7392H17.7391V17.7393Z"
          fill="#D80027"
        />
        <path
          d="M30.2609 17.7393L40.9706 7.02946C40.4782 6.5369 39.9634 6.06702 39.4298 5.6188L30.2609 14.7878V17.7393Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_77_10037">
          <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_77_10037">
          <rect width="72.0001" height="48" fill="white" transform="translate(-17)" />
        </clipPath>
      </defs>
    </svg>
  )
}
