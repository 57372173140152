import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@qasa/qds-ui'

import { Link } from '../../../vendor/next'
import { useAuthContext } from '../../../context/auth-context'
import { getIsProfileCompletenessLow } from '../../profile/profile.utils'
import { getPath } from '../../../routing/get-path'
import { prependSrcWithThumbor } from '../../../helpers/image'
import { NotificationBadge } from '../../../ui-shared/_core/notification-badge'
import { currentBrand } from '../../../config'

const BadgeContainer = styled.div({
  position: 'absolute',
  zIndex: 1,
  right: '-10%',
  top: '-10%',
})
const AvatarLink = styled(Link)({
  position: 'relative',
})

const StyledAvatar = styled(Avatar)<{ isCompanyLandlordWithLogo: boolean }>(
  ({ theme, isCompanyLandlordWithLogo }) => ({
    border: 'none',
    ...(isCompanyLandlordWithLogo && {
      backgroundColor: theme.colors.bg.default,
      img: {
        objectFit: 'contain',
      },
    }),
  }),
)

export function NavAvatar() {
  const { authBody } = useAuthContext()
  const isTenant = authBody?.tenant
  const { t } = useTranslation('top_nav_bar')
  const isQasaFrance = currentBrand === 'qasa_france'

  const isProfileCompletenessLow = getIsProfileCompletenessLow({ user: authBody })
  const profilePictureUrl = authBody?.profilePicture?.url
    ? prependSrcWithThumbor({
        src: authBody.profilePicture.url,
        // a bit arbitrary but it's a small image, yet we don't want too low quality
        width: 252,
        height: 252,
        fittingMethod: 'fit-in',
      })
    : undefined

  const shouldShowNotification = isTenant && isProfileCompletenessLow && !isQasaFrance
  const isCompanyLandlordWithLogo = Boolean(profilePictureUrl) && Boolean(authBody?.professional)

  return (
    <AvatarLink
      aria-label={t('icon_aria_labels.profile')}
      href={getPath(isQasaFrance ? 'settings' : 'profile')}
    >
      {shouldShowNotification && (
        <BadgeContainer>
          <NotificationBadge text={1} height={18} />
        </BadgeContainer>
      )}
      <StyledAvatar
        src={profilePictureUrl}
        name={authBody?.firstName ?? undefined}
        isCompanyLandlordWithLogo={isCompanyLandlordWithLogo}
      />
    </AvatarLink>
  )
}
