import type { SVGProps } from 'react'

export function FrenchFlag({ ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" {...restProps}>
      <g clipPath="url(#clip0_92_1543)">
        <path
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
          fill="#F0F0F0"
        />
        <path
          d="M47.7968 20.8696H18.7827H18.7826V0.570496C16.5647 1.06231 14.462 1.85946 12.5217 2.91818V20.8694V20.8695H0.203156C0.0695625 21.8943 0 22.9391 0 24C0 25.061 0.0695625 26.1057 0.203156 27.1304H12.5216H12.5217V45.0818C14.462 46.1404 16.5647 46.9377 18.7826 47.4295V27.1307V27.1305H47.7968C47.9303 26.1057 48 25.061 48 24C48 22.9391 47.9303 21.8943 47.7968 20.8696V20.8696Z"
          fill="#0052B4"
        />
        <g clipPath="url(#clip1_92_1543)">
          <path d="M-4 0H68.007V48H-4V0Z" fill="#F9F9F6" />
          <path
            d="M68.007 18.1846V29.8154H24.6481V48H13.0173V29.8154H-4V18.1846H13.0173V0H24.6481V18.1846H68.007Z"
            fill="#2E52B2"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M-8 0H56V48H-8V0Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M-7 0H14.33V48H-7V0Z" fill="#00267F" />
          <path fillRule="evenodd" clipRule="evenodd" d="M33.67 0H55V48H33.67V0Z" fill="#F31830" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_92_1543">
          <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_92_1543">
          <rect width="72.007" height="48" fill="white" transform="translate(-4)" />
        </clipPath>
      </defs>
    </svg>
  )
}
